import styled from 'styled-components';

export const Container = styled.footer`
  height: 100px;
  width: 100vw;
  background-color: var(--primary-color);
  font-family: 'Roboto', sans-serif;  

  @media (max-width: 768px) {
    border-top: 2px solid var(--default-gray);
    background-color: var(--bg);
    position: grid;
    height: 10%;  
    bottom: 0;
    text-align: center; 
    padding-top: 0;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-regular);
  line-height: 24px;
  color: var(--white-font-color);
  font-family: 'Roboto', sans-serif;

  .responsiveFooter {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .imgresp{
    display: none;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    color: var(--white-font-color);

  & + a {
      margin-left: 38px;
    }

  img {
      width: 78px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    font-size: var(--font-regular);
    line-height: 20px;
    gap: 30px;
    color: var(--secondary-color);
    margin-top: 22px;
    margin-bottom:32px;

    span {
      width: 300px;
      margin-top: 5px;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    a {
      margin-top: 8px;
      color: var(--secondary-color);

      & + a {
        margin-left: 0px;
        
      }

      .imgresp {
        width: 78px;
        display: block !important;
      }
      
      .imgnoresp{
        display: none;
      }
    }
  }
`;
