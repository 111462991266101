import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(255, 255, 255, 0.95);
  display: none;
  align-items: center;
  justify-content: center;

  & .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

const Loading: React.FC = () => {
  return (
    <LoadingContainer id="loading">
      <div className="spinner-container">
        <div className="spinner"></div>
        <h4>Carregando...</h4>
      </div>
    </LoadingContainer>
  );
};

export default Loading;
