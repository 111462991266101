import CourseFromApi from '../../models/from-api/course';
import Course from '../../models/course';
import convertToCourse from '../../helpers/converters/convert-course';
import checkIfContentsIsLocked from '../../helpers/check-contents-locked';
import httpClient from '../../http-client';
import { PaginateModel } from '../../models/for-request/paginate';

const getCourses = async (filters?: any) => {
  for (let key of Object.keys(filters)) {
    if (!filters[key]) {
      delete filters[key];
    }
  }

  const courses = (await httpClient.get('/trails', { params: filters }))
    .data as CourseFromApi[];

  let convertedCourses = [] as Course[];
  if (courses && courses.length) {
    convertedCourses = courses.map(course => convertToCourse(course));
  }

  return convertedCourses;
};

const getCourse = async (courseId: string): Promise<Course> => {
  const course = (await httpClient.get(`trails/${courseId}`)).data;

  let convertedCourse = {} as Course;

  if (course && course.user) {
    convertedCourse = convertToCourse(course.trail, course.user);
  } else if (course && course.trail) {
    convertedCourse = convertToCourse(course.trail);
  } else {
    convertedCourse = convertToCourse(course);
  }

  if (convertedCourse && convertedCourse.id) {
    await checkIfContentsIsLocked(convertedCourse);
  }

  return convertedCourse || ({} as Course);
};

const toogleBookmark = async (courseId: string, added: boolean) => {
  if (!added) {
    await httpClient.post('/book-marks', { trail_id: courseId });
  } else {
    await httpClient.delete(`/book-marks/${courseId}`);
  }
};

const rateCourse = async (
  trailId: string,
  numberOfStars: number,
  description: string,
) => {
  await httpClient.post(`/trail-user-reviews/`, {
    trail_id: trailId,
    stars: numberOfStars,
    description: description,
  });
};

const startCourse = async (courseId: string) => {
  await httpClient.post('/trail-users', { trail_id: courseId });
};

const finishCourse = async (courseId: string) => {
  await httpClient.put('/trail-users/finish', { trail_id: courseId });
};

const startPreview = async (courseId: string) => {
  return (
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: courseId,
      is_preview: true,
      content_id: null,
      course_id: null,
    })
  ).data;
};

const finishPreview = async (courseId: string) => {
  return (
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: courseId,
      course_id: null,
      content_id: null,
    })
  ).data;
};

const updateLessonProgress = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
  content_view: number | null,
) => {
  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
      content_view,
    })
  ).data;
};

const updatePreviewProgress = async (
  courseId: string,
  content_view: number | null,
) => {
  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: courseId,
      course_id: null,
      content_id: null,
      content_view,
      is_preview: true,
    })
  ).data;
};

const getOngoingCourses = async (data?: PaginateModel) => {
  const ongoingCourses = (
    await httpClient.get<CourseFromApi[]>(`/trail-users/me/in-progress`, {
      params: data
    })
  ).data;

  let convertedCourses = [] as Course[];
  if (ongoingCourses && ongoingCourses.length) {
    convertedCourses = ongoingCourses.map(course => convertToCourse(course));
  }

  return convertedCourses;
};

const getCoursesFromCategory = async (categoryId: string) => {
  const courses = (
    await httpClient.get<CourseFromApi[]>('/categories/attach-trail')
  ).data;

  let convertedCategories = [] as Course[];
  if (courses && courses.length) {
    convertedCategories = courses.map(course => convertToCourse(course));
  }

  return convertedCategories;
};

export {
  getCourse,
  getCourses,
  toogleBookmark,
  rateCourse,
  startCourse,
  finishCourse,
  startPreview,
  finishPreview,
  updateLessonProgress,
  updatePreviewProgress,
  getOngoingCourses,
  getCoursesFromCategory,
};
