import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import type { User } from '../models/from-api/user';
import type ISignInData from '../models/signInData';
import { signIn as signInService } from '../services/singIn';


interface UserToken extends User {
  api_token: string
  user: User
}

type AuthContext = {
  user: UserToken | undefined;
  login: (data: ISignInData) => Promise<void>;
  logout: () => void;
}

type ProviderProps = {
  children: ReactNode;
};

export const Context = createContext({} as AuthContext)

export default function AuthProvider({ children }: ProviderProps) {
  const [user, setUser] = useState<UserToken | undefined>(() => {
    const token = localStorage.getItem('leve-educacao-user');
    return token ? JSON.parse(token) : undefined;
  });

  const history = useHistory();
  
  const login = useCallback(async (data: ISignInData) => {
    try {
      const user = await signInService(data);

      setUser(user);
      localStorage.setItem('leve-educacao-api-token', user.api_token);

      if (!user.user.info.first_login) {
        localStorage.setItem('leve-educacao-api-token', user.api_token);
        localStorage.setItem('leve-educacao-user', JSON.stringify(user.user));
        history.push('/home');
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro ao efetuar login.',
        text: 'A matrícula e a senha estão incorretas ou não existem.',
        icon: 'error',
        confirmButtonColor: '#1d2680',
      });
    };
  }, [history]);

  const logout = useCallback(() => {
    setUser(undefined);
    localStorage.removeItem('leve-educacao-api-token');
    localStorage.removeItem('leve-educacao-user');
    localStorage.removeItem('leveeducacao-api-token');
    localStorage.removeItem('leve-educacao-token');
    history.push('/');

  }, [history])

  const value = useMemo(() => ({ user, login, logout }), [user, login, logout]);
  
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export function useAuth() {
  const { user, login, logout } = useContext(Context);
  return { user, login, logout };
}
