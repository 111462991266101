import styled from 'styled-components';

export const MenuContainer = styled.nav`
  width: 40%;
  display: flex;
  align-items: center;
  margin-left: 56px;
  height: 100%;
  justify-content: space-around;

  a {
    position: relative;
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-large);
    color: #000;

    &:after {
      content: '';
      width: 0%;
      opacity: 0;
      height: 7px;
      bottom: 0;
      left: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      background: var(--secondary-color);
    }

    svg {
      margin-right: 8px;
    }
  }

  a:hover,
  a.active {
    font-weight: 700;
    max-width: 100%;
    color: var(--secondary-color);

    &:after {
      width: 100%;
      opacity: 1;
    }

    svg {
      path {
        stroke: var(--secondary-color);
      } 
    }

  }

  @media (max-width: 768px) {
    display: none;

    a {
      &:after {
        height: 4px;
        top: 0;
      }
    }
  }

  @media (max-width: 992px) {
    display: none;

    svg {
      display: unset;
      margin-bottom: 12px;
    }

    a {
      flex-direction: column;
      font-size: var(--font-smallest);
      color: var(--regular-gray);
      font-weight: 400;
      padding: 0 4px;

      &:hover,
      &.active {
        color: #000;
      }
    }
  }

`;
