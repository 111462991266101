import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logofooter.svg';
import LogoLeve from '../../assets/logoleve.svg';

import { Container, Content } from './style';

const Footer: React.FC = () => {
  return (
    <Container>
      <Content className="content">
        <Link to="/home">
          <img src={Logo} alt="Logo" className="imgnoresp" />
          <img
            src={LogoLeve}
            alt="Logo Leve"
            className="imgresp"
            sizes="max-width: 768px"
          />
        </Link>
        <div>
          <Link to="/faq">FAQ</Link>

          <Link to="/faq#faq-form">Fale Conosco</Link>
        </div>
        <span>
          © Leve EAD - Educação Profissional, 2021, todos os direitos reservados
        </span>
      </Content>
    </Container>
  );
};

export default Footer;
