import React from 'react';
import { FiHome, FiPlay } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import MonitorIcon from '../../assets/icons/MonitorIcon';
import { RiCalendarCheckFill } from 'react-icons/ri';
import { MenuContainer } from './style';


const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <MenuContainer>
      <Link
        to="/home"
        className={`
          ${!location.pathname || location.pathname === '/home' ? 'active' : ''}
        `}
      >
        <FiHome size={20} />
        Início
      </Link>
      <Link
        to="/course-categories"
        className={`
          ${location.pathname === '/course-categories' ? 'active' : ''}
        `}
      >
        <MonitorIcon />
        Cursos
      </Link>
      <Link
        to="/trails"
        className={`
          ${location.pathname === '/trails' ? 'active' : ''}
        `}
      >
        <FiPlay size={20} />
        Trilhas
      </Link>
      <Link
        to="/events"
        className={`
          ${location.pathname === '/events' ? 'active' : ''}
        `}
      >
        <RiCalendarCheckFill />
        Eventos
      </Link>
    </MenuContainer>
  );
};

export default Menu;
