import styled from 'styled-components';

export const Container = styled.form`
  .searchInput {
    width: 110%;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
    padding: 16px;
    margin-right: 90px;
    font-size: var(--font-large);
    font-weight: 400;
    align-items: center;
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    position: relative;
    top: 15px;
  }

  button {
    display: flex;
    position: relative;
    float: right;
    bottom: 20px;
    cursor: pointer;
    height: 100%;
    border: none;
    outline: none;
    background: none;
  }

  @media (max-width: 768px) {
    .searchInput {
      display: none;
    }

    button {
      display: none;
    }
  }
`;
