import React, { createContext, useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Course from '../models/course';
import { getCourses as getCoursesService } from '../services/course';
import { User } from '../models/from-api/user';
interface ISeachProviderProps {
  searchParam: string;
  setSearchParam: React.Dispatch<React.SetStateAction<string>>;
  searchResults: Course[];
  isSearching: boolean;
}

export const SearchContext = createContext<ISeachProviderProps>(
  {} as ISeachProviderProps,
);

export const SearchProvider: React.FC = ({ children }) => {
  const [searchParam, setSearchParam] = useState('');
  const [searchResults, setSearchResults] = useState<Course[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const searchRequest = useCallback(async () => {
    const user = await (JSON.parse(localStorage.getItem('leve-educacao-user') as string));
    
    try {
      if(user) {
        setIsSearching(true);

        const response = await getCoursesService({
          search: searchParam || undefined,
          is_active: true,
        });

        setSearchResults(response);
      }
    } catch (e) {
      toast.error('Erro ao concluir a busca. Tente novamente.');
    }

    setIsSearching(false);
  }, [searchParam]);

  useEffect(() => {
    searchRequest();
  }, [searchParam, searchRequest]);

  return (
    <SearchContext.Provider
      value={{
        searchParam,
        setSearchParam,
        searchResults,
        isSearching,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
