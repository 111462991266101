import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const toogleLoading = (turnOn: boolean) => {
  const noLoading = Array.from(document.getElementsByClassName('no-loading'));
  if (!noLoading.length) {
    const loading = document.getElementById('loading');
    if (loading) {
      window.setTimeout(
        () => {
          loading.style.display = turnOn ? 'flex' : 'none';
        },
        turnOn ? 0 : 1000,
      );
    }
  }
};

httpClient.interceptors.request.use(function (config) {
  toogleLoading(true);

  const token = localStorage.getItem('leve-educacao-api-token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

httpClient.interceptors.response.use(
  function (response) {
    toogleLoading(false);

    return response;
  },
  function (error) {
    toogleLoading(false);
  
    if (error.response && error.response.status === 401) {
      if (
        window.location.pathname !== '/' &&
        !window.location.href.includes('/signIn')
      ) {
        localStorage.removeItem('leve-educacao-api-token');
        localStorage.removeItem('leve-educacao-user');
        localStorage.removeItem('leveeducacao-api-token');
        localStorage.removeItem('leve-educacao-token');
        window.location.href="/"
  
      }
        
    } else {
      return Promise.reject(error);
    }
  },
);

export default httpClient;
